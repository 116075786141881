import React, {ReactElement, useRef} from 'react';
import Button from './Button';

export default function SelectPhoto({
  children,
  onSelect = () => null,
  capture = false,
}: {
  children: string;
  onSelect: (data: string) => void;
  capture?: boolean;
}): ReactElement {
  const ref = useRef(null);
  const onImageChange = (e: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      // @ts-ignore
      const type = reader.result?.match(/^data:(.*);base64,?/)[1];
      // @ts-ignore
      const base64 = reader.result?.replace(`data:${type};base64,`, '');
      // @ts-ignore
      onSelect({
        type,
        base64,
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onClick = () => {
    // @ts-ignore
    ref.current?.click();
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        capture={capture ? 'environment' : undefined}
        onChange={onImageChange}
        ref={ref} // eslint-disable-next-line react-native/no-inline-styles
        style={{visibility: 'hidden', height: 0}}
      />
      <Button onPress={onClick}>{children}</Button>
    </>
  );
}
