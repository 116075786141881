import React, {ReactElement, useState} from 'react';
import StartScreen from './screens/StartScreen';
import RenderScreen from './screens/RenderScreen';
import ScreenEnum from './enums/ScreenEnum';
import NavigationType from './types/NavigationType';

function App(): ReactElement {
  const [screen, setScreen] = useState<ScreenEnum>(ScreenEnum.Start);
  const [data, setData] = useState<any>(null);

  const navigation: NavigationType = {
    screen,
    setScreen,
    data,
    setData,
  };

  return (
    <>
      {screen === ScreenEnum.Start ? (
        <StartScreen navigation={navigation} />
      ) : null}
      {screen === ScreenEnum.Render ? (
        <RenderScreen navigation={navigation} />
      ) : null}
    </>
  );
}

export default App;
