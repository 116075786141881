import {
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Modal from '../components/Modal';
import Button from '../components/Button';
import React, {ReactElement} from 'react';
// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
import {launchCamera, launchImageLibrary} from '../libs/ImagePicker';
import ScreenPropType from '../types/ScreenPropType';
import ScreenEnum from '../enums/ScreenEnum';
import {
  CameraOptions,
  ImageLibraryOptions,
} from 'react-native-image-picker/src/types';
import SelectPhoto from '../components/SelectPhoto';

const photoOptions: CameraOptions | ImageLibraryOptions = {
  mediaType: 'photo',
  includeBase64: true,
};

function StartScreen({navigation}: ScreenPropType): ReactElement {
  const takePhoto = async () => {
    const result = await launchCamera(photoOptions);
    navigation.setData(result.assets?.shift());
    navigation.setScreen(ScreenEnum.Render);
  };

  const selectPhoto = async () => {
    const result = await launchImageLibrary(photoOptions);
    navigation.setData(result.assets?.shift());
    navigation.setScreen(ScreenEnum.Render);
  };

  const onWebSelectPhoto = (data: string) => {
    navigation.setData(data);
    navigation.setScreen(ScreenEnum.Render);
  };

  return (
    <View style={styles.container}>
      <Modal
        title="Testowa wersja aplikacji. Dozwolona tylko do użytku niekomercyjnego."
        buttonText="Ok. Zaczynam testować! ;)"
      />
      <ImageBackground
        source={require('../assets/background.png')}
        resizeMode="cover"
        style={styles.image}>
        <View style={styles.header}>
          <Image style={styles.logo} source={require('../assets/logo.png')} />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>
            Jedyny klimatyzator, który respektuje Twój styl!
          </Text>
          {Platform.OS === 'web' ? (
            <>
              <SelectPhoto capture={true} onSelect={onWebSelectPhoto}>
                Zrób zdjęcie
              </SelectPhoto>
              <SelectPhoto onSelect={onWebSelectPhoto}>
                Wybierz zdjęcie
              </SelectPhoto>
            </>
          ) : null}
          {Platform.OS !== 'web' ? (
            <>
              <Button onPress={() => takePhoto()}>Zrób zdjęcie</Button>
              <Button onPress={() => selectPhoto()}>Wybierz zdjęcie</Button>
            </>
          ) : null}
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    margin: 20,
  },
  button: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 20,
    marginRight: 10,
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  logo: {
    height: 200,
    width: 300,
    resizeMode: 'contain',
  },
  header: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  content: {
    height: '40%',
    backgroundColor: '#fff',
    padding: 25,
    paddingTop: 40,
    borderTopStartRadius: 40,
    borderTopEndRadius: 40,
  },
  title: {
    textAlign: 'center',
    marginBottom: 20,
  },
  photo: {
    width: 200,
    height: 200,
  },
});

export default StartScreen;
