import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import React, {ReactElement} from 'react';

export default function Button({
  children = '',
  onPress = () => {},
}): ReactElement {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text style={styles.text}>{children}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#fff',
    borderColor: '#d6d6d6',
    borderWidth: 1,
    padding: 15,
    borderRadius: 40,
    marginBottom: 10,
  },
  text: {
    textAlign: 'center',
  },
});
