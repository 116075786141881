import React, {useEffect} from 'react';
import {StyleSheet} from 'react-native';

const WebView = React.forwardRef((props: any, ref: any) => {
  useEffect(() => {
    const handler = (ev: any) => {
      if (ev.source === ref.current.contentWindow) {
        props.onMessage(ev);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [ref, props]);

  return <iframe ref={ref} src={props.source.uri} style={styles.iframe} />;
});

export default WebView;

const styles = StyleSheet.create({
  iframe: {
    flex: 1,
    border: 0,
  },
});
