import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import WebView from './WebView';
import {Platform} from 'react-native';

export type BrowserRefType = {
  sendMessage: (message: string) => void;
};

type BrowserProps = {
  url: string;
  onMessage: (data: string) => void;
};

const Browser = forwardRef(function (
  {url, onMessage = () => null}: BrowserProps,
  ref,
) {
  const webRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        sendMessage(data: any) {
          // @ts-ignore
          if (Platform.OS === 'ios') {
            // @ts-ignore
            webRef.current?.postMessage(data);
          } else if (Platform.OS === 'android') {
            // @ts-ignore
            webRef.current?.postMessage(data);
          } else {
            // @ts-ignore
            webRef.current?.contentWindow.postMessage(data, '*');
          }
        },
      };
    },
    [],
  );

  const onMessageCallback = (e: any) => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      onMessage(e.nativeEvent.data);
    } else {
      onMessage(e.data);
    }
  };

  return (
    <WebView
      source={{uri: url}}
      ref={webRef}
      onMessage={onMessageCallback}
      bounces={false}
      automaticallyAdjustContentInsets={false}
    />
  );
});

export default Browser;
