import React, {ReactElement, useRef} from 'react';
import {Platform, SafeAreaView, Share, StyleSheet, View} from 'react-native';
import ScreenPropType from '../types/ScreenPropType';
import ScreenEnum from '../enums/ScreenEnum';
import MessageActionEnum from '../enums/MessageActionEnum';
import Browser, {BrowserRefType} from '../components/Browser/Browser';
import Config from '../config/Config';

function RenderScreen({navigation}: ScreenPropType): ReactElement {
  const browserRef = useRef<BrowserRefType>(null);

  const onMessage = (event: any) => {
    const {action, data} = JSON.parse(event);

    if (action === MessageActionEnum.LOADED) {
      console.log('upload_photo_payload', {
        ...navigation.data,
        ...{base64: ''},
      });
      browserRef.current?.sendMessage(
        JSON.stringify({
          action: MessageActionEnum.UPLOAD_PHOTO,
          data: navigation.data,
        }),
      );
    } else if (action === MessageActionEnum.BACK) {
      navigation.setScreen(ScreenEnum.Start);
    } else if (action === MessageActionEnum.SAVE) {
      if (Platform.OS === 'ios') {
        Share.share({
          url: data.base64,
        }).then();
      } else if (Platform.OS === 'android') {
        // TODO
      } else {
        const link = document.createElement('a');
        link.href = data.base64;
        if (data.textureName && data.textureGroup) {
          link.download = `${data.textureName}-${data.textureGroup}`;
        } else {
          link.download = 'no-texture';
        }
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView />
      <Browser
        ref={browserRef}
        url={String(Config.REACT_APP_BROWSER_URL)}
        onMessage={onMessage}
      />
    </View>
  );
}

export default RenderScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
